import React from 'react';
import './DGReview.css'; // Import the CSS file
import { reviews } from '../../Constants';
import {Helmet} from 'react-helmet';

// Individual Review Component
const Review = ({ stars, name, reviewText }) => {
    const fullStars = Math.floor(stars);
    const halfStar = stars % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
  
    const renderStars = () => (
      <>
        {[...Array(fullStars)].map((_, i) => (
          <span key={i} className="star full">&#9733;</span> // full star
        ))}
        {halfStar && <span className="star half">&#9733;</span>} {/* half star */}
        {[...Array(emptyStars)].map((_, i) => (
          <span key={i + fullStars + 1} className="star empty">&#9733;</span> // empty star
        ))}
      </>
    );

  return (
    <div className="reviewCard">
      <div className="stars">{renderStars()}</div>
      <p className="text">{reviewText}</p>
      <strong>{name}</strong>
    </div>
  );
};

// Main Reviews Section Component
const DGReview = () => {
  return (
    <section className="reviewsContainer">
      <h1 className="reviewsHeading">Ratings & Reviews</h1>
      <Helmet>
        <title>
        Best Spa at Dhaka
        </title>
        <meta name='description'
        content='
        Discover customer reviews and ratings for Dreamgirl Spa, where clients rave about our exceptional massage services, skilled therapists, and tranquil ambiance. With consistently high ratings, Dreamgirl Spa is praised for delivering relaxation, rejuvenation, and personalized care. Read what satisfied customers say and experience the luxury of true wellness today!'
        />
    </Helmet>
      <div className="reviewsGrid">
        {reviews.map((review, index) => (
          <Review key={index} {...review} />
        ))}
      </div>
    </section>
  );
};

export default DGReview;
