import React from 'react';
import './DGContact.css'; // Import your styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { PHONE_NUMBER, ADDRESS_DESC, ADDRESS_LAT, ADDRESS_LONG } from '../../Constants';
import {Helmet} from 'react-helmet';

const DGContact = () => {
  const handleMapRedirect = () => {
    const url = `https://www.google.com/maps?q=${ADDRESS_LAT},${ADDRESS_LONG}`;
    window.open(url, '_blank');
  };
  return (
    <div className="contact-section">
      <header className="contact-header">
        <h1>CONTACTS</h1>
        <p>
          Welcome to our professional spa salon where you can find any type of treatment for your
          specific needs or preferences!
        </p>
      </header>
      <Helmet>
        <title>
        Contact Spa in gulshan dhaka
        </title>
        <meta name='description'
        content='Get in touch with Dreamgirl Spa to book your next relaxation experience. Our friendly staff is available to answer questions and assist with scheduling. Reach us by phone, email, or visit us in person. We are committed to making your spa visit seamless and rejuvenating from start to finish!'
        />
    </Helmet>
      <div className="contact-cards">
      <div className="contact-card" onClick={handleMapRedirect} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
          <h3>Address</h3>
          <p>{ADDRESS_DESC}</p>
        </div>

        <div className="contact-card">
          <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
          <h3>Phone</h3>
          <a href={`https://wa.me/${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
        </div>

        <div className="contact-card">
          <FontAwesomeIcon icon={faClock} className="icon" />
          <h3>Work Hours</h3>
          <p>Sat - Sun: 10.00 AM - 10.00 PM</p>
        </div>
      </div>
    </div>
  );
};

export default DGContact;
