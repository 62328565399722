import React, { useState } from 'react';
import "./App.css";
import { FaWhatsapp, FaTelegramPlane, FaPhoneAlt, FaComments } from 'react-icons/fa'; 
import { PHONE_NUMBER } from './Constants';
import DGServices from './Components/services/DGServices';
import DGDescription from './Components/description/DGDescription';
import DGReview from './Components/review/DGReview';
import DGNavBar from './Components/navbar/DGNavBar';
import DGContact from './Components/contact/DGContact';
import { Routes, Route} from 'react-router-dom';
import Home from './Components/home/Home';
import MediaGallery from './Components/gallery/MediaGallery';
import DGFaq from './Components/faq/DGFaq';
import {Helmet} from 'react-helmet';


function App() {

    // State to manage visibility of icons
    const [showIcons, setShowIcons] = useState(false);

    // Toggle the visibility of the icons
    const toggleIcons = () => {
      setShowIcons(!showIcons);
    };

  return (
    <div className="App">
      <Helmet>
        <title>
          Dream Girl Thai Spa
        </title>
        <meta name='description'
        content='Best Spa in Dhaka'
        />
      </Helmet>
          <DGNavBar />
        <Routes>
            <Route index element = {<Home/>} />
            <Route path='/best-spa-in-dhaka/spa-service-pricing' element = {<DGServices/>}/>
            <Route path='/best-spa-at-dhaka/description' element = {<DGDescription/>}/>
            <Route path='/best-spa-at-dhaka/contact-spas' element = {<DGContact/>}/>
            <Route path='/best-spa-at-dhaka/review-rating' element = {<DGReview/>}/>
            <Route path='/spas/photos_gulshan_spa' element = {<MediaGallery/>}/>
            <Route path='/massage-center-daka/faq' element = {<DGFaq/>}/>
            <Route path='*' element = {<Home/>}/>
        </Routes>

     <DGContact/>
     {/* Floating Message Button */}
     <div className="floating-message-button" onClick={toggleIcons}>
        <FaComments />
      </div>

      {/* Conditional rendering of the icons */}
      {showIcons && (
        <div className="floating-icons">
          <a 
            href={`https://wa.me/${PHONE_NUMBER}`}
            className="whatsapp-icon" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
          </a>
          <a 
            href={`https://t.me/${PHONE_NUMBER}`}
            className="telegram-icon" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FaTelegramPlane />
          </a>
          <a 
            href={`tel:${PHONE_NUMBER}`}
            className="call-icon"
          >
            <FaPhoneAlt />
          </a>
        </div>
      )}
    </div>

  );
}

export default App;
