// src/constants.js

export const APP_NAME = 'Dream Girl Thai Spa';
export const ADDRESS_DESC = '37, RM Center, Plot-5, 101 Gulshan Ave, Dhaka 1212';
export const ADDRESS_LAT = '+23.7821503';
export const ADDRESS_LONG = '+90.4156781';
export const PHONE_NUMBER = '+8801814388809';

//social media link
export const SOCIAL_MEDIA_LINK_YOUTUBE = "https://www.youtube.com/channel/UCtX3h8375dXGCnB8WUsMenw";
export const SOCIAL_MEDIA_LINK_FACEBOOK = "https://www.facebook.com/profile.php?id=61566401951344&mibextid=LQQJ4d";
export const SOCIAL_MEDIA_LINK_INSTAGRAM = "https://www.instagram.com/dreamgirlthaispa/profilecard/?igsh=MXAxN3U3bnBqYWsxNw==";


export const SPA_SERVICES = [
  {
    name: "Body to Body(B2B) Massage",
    imageUrl: '/assets/nuru.jpg',
    pricing: [
      { duration: "60 Minutes", price: "8000 tk" },
      { duration: "90 Minutes", price: "12000 tk" },
      { duration: "120 Minutes", price: "15000 tk" },
    ],
  },
  {
    name: "Dry Massage",
    imageUrl: '/assets/dry_message.jpg',
    pricing: [
      { duration: "60 Minutes", price: "5000 tk" },
      { duration: "90 Minutes", price: "6000 tk" },
      { duration: "120 Minutes", price: "8000 tk" },
    ],
  },
  ,
  {
    name: "Oil Massage",
    imageUrl: '/assets/aroma.jpg',
    pricing: [
      { duration: "60 Minutes", price: "6000 tk" },
      { duration: "90 Minutes", price: "9000 tk" },
      { duration: "120 Minutes", price: "12000 tk" },
    ],
  },
  {
    name: "Four Hand Massage",
    imageUrl: '/assets/four_hand.jpg',
    pricing: [
      { duration: "60 Minutes", price: "10500 tk" },
      { duration: "90 Minutes", price: "14500 tk" },
      { duration: "120 Minutes", price: "20500 tk" },
    ],
  },
  {
    name: "Thai Massage",
    imageUrl: '/assets/thai.jpg',
    pricing: [
      { duration: "60 Minutes", price: "5000 tk" },
      { duration: "90 Minutes", price: "6000 tk" },
      { duration: "120 Minutes", price: "8000 tk" },
    ],
  },
  {
    name: "Sensual Massage",
    imageUrl: '/assets/sensual.jpg',
    pricing: [
      { duration: "60 Minutes", price: "6500 tk" },
      { duration: "90 Minutes", price: "9500 tk" },
      { duration: "120 Minutes", price: "10500 tk" },
    ],
  },
  {
    name: "Aroma Oil Massage",
    imageUrl: '/assets/aroma.jpg',
    pricing: [
      { duration: "60 Minutes", price: "4500 tk" },
      { duration: "90 Minutes", price: "5500 tk" },
      { duration: "120 Minutes", price: "8500 tk" },
    ],
  },
  {
    name: "Nuru Massage",
    imageUrl: '/assets/nuru.jpg',
    pricing: [
      { duration: "60 Minutes", price: "7500 tk" },
      { duration: "90 Minutes", price: "10500 tk" },
      { duration: "120 Minutes", price: "13500 tk" },
    ],
  },
  ,
  {
    name: "Foot Massage",
    imageUrl: '/assets/foot.jpg',
    pricing: [
      { duration: "60 Minutes", price: "3500 tk" },
      { duration: "90 Minutes", price: "5000 tk" },
      { duration: "120 Minutes", price: "6500 tk" },
    ],
  },
  ,
  {
    name: "Back and Shoulder Massage",
    imageUrl: '/assets/back_shoulder.jpg',
    pricing: [
      { duration: "60 Minutes", price: "4500 tk" },
      { duration: "90 Minutes", price: "6500 tk" },
      { duration: "120 Minutes", price: "8000 tk" },
    ],
  },
  ,
  {
    name: "Special Massage",
    imageUrl:  '/assets/special.jpg',
    pricing: [
      { duration: "60 Minutes", price: "7000 tk" },
      { duration: "90 Minutes", price: "9500 tk" },
      { duration: "120 Minutes", price: "12500 tk" },
    ],
  },
  ,
  {
    name: "Full Body Massage",
    imageUrl: '/assets/full_body.jpg',
    pricing: [
      { duration: "60 Minutes", price: "4500 tk" },
      { duration: "90 Minutes", price: "6500 tk" },
      { duration: "120 Minutes", price: "8000 tk" },
    ],
  },
];

export  const faqData = [
    {
      question: 'What services are you offering?',
      answer: 'We provide an extensive range of spa services, such as massages, facial treatments, body therapies, and additional offerings.',
    },
    {
      question: 'How to book appointment to your services?',
      answer: `You can book an appointment by visiting our website and using the online booking system. Alternatively, you can call our reception desk directly at ${PHONE_NUMBER} to schedule your visit. If you prefer, you can also email us at help@dreamgirl-spa.com , and we’ll be happy to assist you with your appointment.`,
    },
    {
      question: 'What safety and hygiene measures are in place?',
      answer: `At ${APP_NAME}, the health and safety of our clients are our top priorities. We conduct regular cleaning and sanitization of our facilities and implement stringent hygiene protocols, which include sterilizing all tools and equipment after each use. Additionally, we follow recommended health guidelines to create a safe environment for all our guests.`,
    },
    {
      question: 'What are your hours of operation?',
      answer: 'We are open every day from 10:00 AM to 10:00 PM. Please check our website or contact us directly for any updates on holiday hours or special closures.',
    },
    {
      question: 'What should I bring to my appointment?',
      answer: 'We offer a full range of amenities, such as robes, slippers, and towels, for your convenience. If you intend to use our sauna or steam room, we recommend bringing a swimsuit. Otherwise, just come as you are and enjoy a relaxing experience!'
    },
    {
      question: 'What attire is recommended for my treatment?',
      answer: 'For most treatments, we supply robes and disposable undergarments for your comfort. During massages, you will be covered with a sheet or towel, exposing only the area being treated.'
    },
    {
      question: 'Do you have male and female therapists?',
      answer: 'Yes, we have both male and female therapists. You can request your preference when booking, and we’ll do our best to accommodate your request.'
    
    }
    ];

    export const reviews = [
      { stars: 4.5, name: 'Abhisekh(India)', reviewText: `${APP_NAME}exceeded my expectations, providing a truly rejuvenating experience.` },
      // { stars: 5, name: 'Mujibur Rahman(Cumilla,BD)', reviewText: `The therapists at ${APP_NAME} are exceptional. I felt refreshed and relaxed after my session.` },
      // { stars: 4.5, name: 'Rumon(Sylhet,BD)', reviewText: `I absolutely loved the ambiance at ${APP_NAME}. It’s a perfect escape from the city hustle.` },
      // { stars: 4.5, name: 'Musa(Turkey)', reviewText: `My experience at ${APP_NAME} was fantastic! The services are top-notch and the staff is so friendly.` },
      { stars: 4.5, name: 'Mùchén(China)', reviewText: `${APP_NAME} is a hidden gem in the city. I felt so relaxed and rejuvenated after my visit. Highly recommended.` },
      // { stars: 5, name: 'Shajon(Chattogram, BD)', reviewText: `I highly recommend ${APP_NAME} for anyone looking to unwind and de-stress. The services are superb!` },
      { stars: 5, name: 'Babul(Gulshan,Dhaka)', reviewText: `I recently visited ${APP_NAME} in Gulshan, Dhaka, and it was an unforgettable experience! From the moment I stepped in, I was welcomed by the serene ambiance and friendly staff. The spa offers a wide range of services, including traditional Thai massages, rejuvenating facials, and luxurious body treatments that cater to every need.` },
      { stars: 5, name: 'Mukhles(Dinajpur)', reviewText: `Whether you’re looking for a relaxing massage or a more intensive treatment, the spa offers a variety of services tailored to meet your needs. The staff is friendly and attentive, ensuring a personalized experience from start to finish.`},
    ];
  
   export const SPA_DESCRIPTION = [
      {
        title: 'Oil Massage',
        description:
          'Relax with our oil massage, where our therapists use gentle strokes and aromatic oils to ease your tension and help you unwind.',
      },
      {
        title: 'Hot Oil Massage',
        description:
          'Experience deep relaxation with our hot oil massage. Warm oils are used to melt away muscle tension and create a calm, peaceful feeling.',
      },
      {
        title: 'Body-to-body Massage',
        description:
          'Our body-to-body massage offers a unique and deeply relaxing experience where the therapist uses their body to massage yours, providing a sensual and comforting sensation.',
      },
      {
        title: 'Body Scrub Massage',
        description:
          'Refresh your skin with our body scrub massage. A gentle exfoliating scrub is used to remove dead skin cells, leaving your skin smooth and glowing.',
      },
      {
        title: 'Special Massages',
        description:
          'We offer a variety of special massages to meet your specific needs. Whether you need a deep tissue massage to relieve muscle tension or a prenatal massage for pregnancy support, our therapists will personalise your treatment.',
      },
      {
        title: 'Body Massage',
        description:
          'Enjoy a traditional body massage that focuses on relieving muscle tension and promoting overall relaxation. Our experienced therapists will tailor the massage to suit your needs and preferences.',
      },
    ];

    export const media = [
      { type: 'image', url:  '/gallery/gallery_4.jpeg'},
      { type: 'video', url:  '/gallery/gallery_video_1.mp4'},
      { type: 'image', url:  '/gallery/gallery_1.jpg'},
      // { type: 'image', url:  '/gallery/gallery_2.jpg'},
      { type: 'image', url:  '/gallery/gallery_3.jpg'},
      { type: 'image', url:  '/gallery/gallery_5.avif'},
      { type: 'image', url:  '/gallery/gallery_6.jpg'},


    ];