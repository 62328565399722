import React from 'react';
import "./home.css";
import Banner from '../banner/Banner';
import DGFaq from '../faq/DGFaq';
import DGReview from '../review/DGReview';
import DGServices from '../services/DGServices';
import DGDescription from '../description/DGDescription';
import MediaGallery from '../gallery/MediaGallery';
import {Helmet} from 'react-helmet';


const Home = () => {
  return (
   <div className="home-container">
    <Helmet>
        <title>
        Dream Girl SPA| Spa in Dhaka | Best spas in Gulshan
        </title>
        <meta name='description'
        content='Welcome to Dreamgirl Spa, your ultimate destination for relaxation and rejuvenation. We specialize in luxurious massages and spa treatments designed to pamper your body and soothe your mind. Our experienced therapists offer a variety of services, including aromatherapy, Swedish massage, deep tissue massage, and more, tailored to meet your specific needs. Immerse yourself in our serene environment, where every detail is crafted to promote tranquility and well-being. At Dreamgirl Spa, we are committed to providing a rejuvenating experience that leaves you feeling refreshed, revitalized, and ready to take on the world. Your relaxation is our priority!'
        />
    </Helmet>
    <Banner/>
    <MediaGallery/>
    <DGReview/>
    <DGServices/>
    <DGDescription/>
    <DGFaq/>
    {/* <DGContact/> */}
   </div>
  );
}

export default Home