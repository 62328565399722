import React from "react";
import './DGServices.css'; // For CSS styles
import {SPA_SERVICES} from "../../Constants";
import {Helmet} from 'react-helmet';

const DGServices = () => {
  return (
    <div className="services-container">
      <Helmet>
        <title>
        Spa price in BD
        </title>
        <meta name='description'
        content='Dreamgirl Spa offers a variety of massages tailored to your needs. Choose from the soothing Swedish massage, the therapeutic deep tissue, or the calming hot stone massage. Our aromatherapy adds essential oils for relaxation, while the Thai massage blends stretching and acupressure for a holistic wellness experience.'
        />
    </Helmet>
      <h1>Services & Pricing</h1>
      <div className="services-grid">
        {SPA_SERVICES.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.imageUrl} alt={service.name} className="service-image" />
            <h2>{service.name}</h2>
            <ul className="pricing-list">
              {service.pricing.map((priceOption, index) => (
                <li key={index}>
                  {priceOption.duration}: <strong>{priceOption.price}</strong>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DGServices;
