import React from 'react';
import './banner.css';
import { APP_NAME, PHONE_NUMBER } from '../../Constants'
import backgroundImage from '../../assets/back_image.jpg';
import Slider from 'react-slick'; 






const Banner = () => {
  const handleBookNow = () => {
    const message = 'Hello! I would like to know more about your services.'; 
    const url = `https://wa.me/${PHONE_NUMBER}?text=${encodeURIComponent(message)}`;
    
    window.open(url, '_blank'); 
  };
  return (
    <div className="banner" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="overlay">
        <div className="content">
          <h1>{APP_NAME}</h1>
          <p>We are Passionate Spa and Massage Services Based in Gulshan, Dhaka.</p>
          <p>Looking for the best spa services in town? Our team of experienced therapists offers all the spa services you’ve been waiting for.</p>
        
          
          <button className="book-button" onClick={handleBookNow}>Book Appointment</button>
        </div>
      </div>
    </div>
  );

  
};

export default Banner;
