import React from 'react';
import './MediaGallery.css';
import { media } from '../../Constants'
import {Helmet} from 'react-helmet';

const MediaGallery = () => {
  return (
    <div className='container'>
            <h1>Best Spa in Dhaka City</h1>
            <h2>Explore in photos & videos</h2>
        <Helmet>
        <title>
        Spa Photos Gulshan Dhaka
        </title>
        <meta name='description'
        content='Explore our photo gallery at Dreamgirl Spa to experience the serene ambiance, luxurious treatment rooms, and tranquil environment. Get a glimpse of our skilled therapists in action, soothing massages, and wellness treatments. Browse through images that showcase the ultimate relaxation and rejuvenation awaiting you at Dreamgirl Spa.'
        />
    </Helmet>
    <div className="media-gallery-container">
      {media.map((item, index) => (
        <div key={index} className="media-item">
          {item.type === 'image' ? (
            <img src={item.url} alt={`media-${index}`} />
          ) : (
            <video controls>
              <source src={item.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ))}
    </div>
    </div>
  );
};

export default MediaGallery;
