import React from 'react';
import './DGDescription.css'; 
import { APP_NAME, SPA_DESCRIPTION } from '../../Constants';
import {Helmet} from 'react-helmet';



const DGDescription = () => {
  return (
    <div className="spa-services-container">
      <Helmet>
        <title>
        spa in gulshan dhaka
        </title>
        <meta name='description'
        content='At Dream Girl Spa, we offer a wide range of massage services designed to meet your unique relaxation and therapeutic needs. Our Swedish massage uses gentle, flowing strokes to promote relaxation and ease tension. For those looking for deeper relief, our deep tissue massage targets muscle knots and chronic pain. The hot stone massage combines soothing heat with therapeutic pressure for enhanced relaxation. We also offer aromatherapy massage, incorporating essential oils to elevate your mood and well-being. For a holistic experience, our Thai massage combines stretching and acupressure to restore balance and vitality. Relax, unwind, and rejuvenate!'
        />
    </Helmet>
      <h1>Enjoy a Relaxing Experience at {APP_NAME}</h1>
      <h2>Best massage center in Dhaka</h2>
      <p>
        Welcome to {APP_NAME}! We offer a range of spa services designed to help you relax
        and feel refreshed. Our skilled therapists are dedicated to providing a soothing experience
        that will leave you feeling renewed and revitalised.
      </p>

      <h2>Spa Services at {APP_NAME}</h2>
      <p>Here’s a list of the spa services we offer:</p>

      <ul className="spa-services-list">
        {SPA_DESCRIPTION.map((service, index) => (
          <li key={index} className="spa-service-item">
            <strong>{service.title}:</strong> {service.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DGDescription;
