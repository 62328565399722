import React, { useState } from 'react';
import { faqData} from '../../Constants';
import "./DGFaq.css";
import {Helmet} from 'react-helmet';


const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item-container">
      <div onClick={toggleOpen} className="question">
        {isOpen ? '−' : '+'} {question}
      </div>
      {isOpen && <div className="answer">{answer}</div>}
    </div>
  );
};

const DGFaq = () => {
  return (
    <div className='faq-container'>
      <Helmet>
        <title>
        Spa
        </title>
        <meta name='description'
        content='Spa'
        />
    </Helmet>
    <h1>Best Spa in Gulshan, Dhaka</h1>
    <h2>Frequently Asked Questions(FAQ)</h2>
      {faqData.map((item, index) => (
        <FaqItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default DGFaq;
