// src/components/DGNavbar.js

import React, { useState } from 'react';
import { FaPhoneAlt, FaMapMarkerAlt, FaFacebookF, FaYoutube, FaInstagram } from 'react-icons/fa';
import { PHONE_NUMBER,SOCIAL_MEDIA_LINK_YOUTUBE,SOCIAL_MEDIA_LINK_FACEBOOK,SOCIAL_MEDIA_LINK_INSTAGRAM,ADDRESS_DESC, ADDRESS_LAT, ADDRESS_LONG, APP_NAME } from '../../Constants';
import './DGNavbar.css'; 
import { useNavigate } from 'react-router-dom';

const DGNavbar = () => {
      // State to manage menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  };
   // Close the mobile menu when a nav link is clicked
    const closeMenu = (value) => {
    setIsMenuOpen(false);
    // Navigate based on the clicked link
    switch (value) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/spas/photos_gulshan_spa');
        break;
      case 2:
        navigate('/best-spa-at-dhaka/review-rating');
        break;
      case 3:
        navigate('/best-spa-in-dhaka/spa-service-pricing');
        break;
      case 4:
        navigate('/best-spa-at-dhaka/description');
        break;
      case 5:
          navigate('/massage-center-daka/faq');
          break;
      case 6:
            // navigate('/best-spa-at-dhaka/contact-spas');
            scrollToBottom();
            break;
      default:
        navigate('/');
        break;
    }
  };

  

  const handleMapRedirect = () => {
    const url = `https://www.google.com/maps?q=${ADDRESS_LAT},${ADDRESS_LONG}`;
    window.open(url, '_blank'); // Open the map in a new tab
  };

  return (
    <div className="navbar-container">
      {/* Top info bar */}
      <div className="top-bar">
        <div className="contact-info">
        <span>
            <a href={`tel:${PHONE_NUMBER}`} style={{ color: 'inherit', textDecoration: 'none' }}>
            <FaPhoneAlt /> {PHONE_NUMBER}
           </a>
        </span>
        <div onClick={handleMapRedirect} style={{ cursor: 'pointer' }}>
          <span><FaMapMarkerAlt /> {ADDRESS_DESC}</span>
          </div>
        </div>
        <div className="social-icons">
          <div className="social-icon facebook">
            <a href={SOCIAL_MEDIA_LINK_FACEBOOK}
              target="_blank" 
              rel="noopener noreferrer">
                <FaFacebookF /></a>
          </div>
          <div className="social-icon instagram">
            <a href={SOCIAL_MEDIA_LINK_INSTAGRAM}
              target="_blank" 
              rel="noopener noreferrer">
                <FaInstagram /></a>
          </div>
          <div className="social-icon youtube">
            <a 
              href={SOCIAL_MEDIA_LINK_YOUTUBE}
              target="_blank" 
              rel="noopener noreferrer">
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>

      {/* Navbar */}
       {/* Navbar */}
       <nav className="navbar">
       {/* <div className='navbar-title'>
          <h2>{APP_NAME}</h2>
        </div> */}
        {/* Hamburger Icon */}
        <div className="hamburger" onClick={toggleMenu}>
          <div className={`bar ${isMenuOpen ? 'active' : ''}`}></div>
          <div className={`bar ${isMenuOpen ? 'active' : ''}`}></div>
          <div className={`bar ${isMenuOpen ? 'active' : ''}`}></div>

        </div>
        <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <li><a href="#" onClick={() => closeMenu(0)}>Home</a></li>
          <li><a href="#" onClick={() => closeMenu(1)}>Gallery</a></li>
          <li><a href="#" onClick={() => closeMenu(2)}>Reviews</a></li>
          <li><a href="#" onClick={() => closeMenu(3)}>Services & Pricing</a></li>
          <li><a href="#" onClick={() => closeMenu(4)}>Explore</a></li>
          <li><a href="#" onClick={() => closeMenu(5)}>FAQ</a></li>
          <li><a href="#" onClick={() => closeMenu(6)}>Contact</a></li>

        </ul>
      </nav>
    </div>
  );
}

export default DGNavbar;
